<template>
    <b-card no-body>
        <v-table    ref="table"
                    :items-provider="provider"
                    :fields="fields"
                    pagination="server"
                    button-alignment="justify-content-start"
                    :buttons="buttons"></v-table>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { IrsaliyeOperationsApi } from '@/api/irsaliye';

export default {
    components:{
        BCard
    },
    data(){
        return {
            fields:[
                {name:'Referans Numarası',key:'ReferenceNo',thClass:''},
                {name:'Cari Kodu',key:'CarrierCariCode',thClass:''},
                {name:'Cari',key:'CarrierCariName',thClass:'',tdClass:'text-nowrap'},
                {name:'Durum',key:'StatusStr',thClass:'',tdClass:'text-nowrap'},
                {name:'Fatura Numarası',key:'InvoiceNo',thClass:''},
                {name:'Fatura Durumu',key:'InvoiceStatus',thClass:'',tdClass:'text-nowrap'},
                {name:'Oluşturulma Tarihi',key:'CreateTime',thClass:''},
                {name:'Başlangıç Tarihi',key:'StartDate',thClass:''},
                {name:'Bitiş Tarihi',key:'EndDate',thClass:''},
            ],
            buttons:[
                {text:"Fatura Kesildi Olarak İşaret",key:"set",icon:"EyeIcon",variant:"secondary",action:this.goIrsaliye},
                {text:"Fatura Kesildi Olarak İşaret",key:"set",icon:"CheckCircleIcon",variant:"primary",action:this.set,condition:(item) => item.Status !=11 && (item.Status == 9 || item.Status == 10 || item.InvoiceStatusKey != 'invoice.not.found' || item.InvoiceStatusKey != 'invoice.total.amount.not.matched')},
            ]
        }
    },
    methods:{
        provider(data){
            return IrsaliyeOperationsApi.PricesSentToCustomer(data)
        },
        goIrsaliye(d){
            let route = this.$router.resolve({ name:'waybill-detail', params:{id:d.Id}})
            window.open(route.href, "_blank")
        },
        set(item){
            this.$confirm('Onaylamak istediğinize emin misiniz?',`Bu işlem faturanın kesildiğini onaylar ve geri alınamaz.`).then(res=>{
                if(res){
                    this.$refs.table.rowLoading(true)
                    IrsaliyeOperationsApi.SetBillHasBeenInvoicedToIrsaliyeGroup(item.Id).then(res=>{
                        this.$result(res)
                        this.$refs.table.rowLoading(false)
                        if(res.data.Success){
                            this.$refs.table.refresh()
                        }
                    }).catch(err=>{
                        this.$refs.table.rowLoading(false)
                        this.$result(null,err)
                    })
                }
            })
        }
    }
}
</script>